<template>
  <div class="fb-page">
    <div class="fb-header">
      <div class="fb-content">
        <h2 class="title">意见反馈</h2>
        <span class="tips">请描述您的问题，以便我们尽快为您处理</span>
      </div>
    </div>
    <div class="fb-container">
      <div class="fb-block">
        <div class="fb-title">
          <h3>您的联系方式</h3>
          <!-- <span class="grey">(电话/邮箱)</span> -->
          <span class="red">必选</span>
        </div>
        <div class="fb-inner">
          <input type="tel" placeholder="请输入联系电话" maxlength="11" v-model="contact">
        </div>
      </div>
      <div class="fb-block">
        <div class="fb-title">
          <h3>反馈内容</h3>
          <span class="red">必选</span>
        </div>
        <div class="textarea-box">
          <textarea class="textarea" name="txt" id="" cols="30" rows="10" placeholder="请输入您要反馈的问题"
            maxlength="300" v-model="content"
            ></textarea>
          <div class="length-area">{{ content.length }}/300</div>
        </div>
      </div>
      <div class="fb-block">
        <div class="fb-title">
          <h3>点击上传图片</h3>
          <span class="grey">(选填，最多可上传3张)</span>
        </div>
        <div class="fb-upload-container">
          <van-uploader v-model="fileList" multiple 
            :max-count="3"
            :before-read="beforeUpload" :after-read="afterRead"
            :upload-icon="uploadIcon"/>
        </div>
      </div>
      
    </div>
    <div class="ft-placeholder"></div>
    <div class="footer-bar">
      <van-button class="footer-btn" type="info" @click="onSubmit" 
        :loading="loading"
      >立即提交</van-button>
    </div>
  </div>
</template>

<script>
  import { Button, Uploader, Toast, Dialog } from 'vant'
  import uploadIcon from '@/assets/images/icon/icon_camera.svg'
  import { isPhoneNumber } from '@/utils/common'
  import { uploadFile, addFeedback } from '@/api/common'
  import Cookies from 'js-cookie'
  import md5 from 'md5'
  
  export default {
    components: {
      [Button.name]: Button,
      [Uploader.name]: Uploader
    },
    data() {
      return {
        content: '',
        contact: '',
        loading: false,
        fileList: [],
        uploadIcon,
      }
    },
    created() {
      
    },
    methods: {
      beforeUpload(file) {
        if (file instanceof Array === false) {
          file = [file]
        }
        let isImg = true
        file.forEach(el => {
          if (el.type.indexOf('image') < 0) {
            isImg = false
          }
        })
        if (!isImg) {
          Toast('文件必须是图片格式!');
        }
        return isImg
      },
      afterRead(file) {
        if (file instanceof Array === false) {
          file = [file]
        }
        file.forEach(el => {
          const formData = new FormData(); 
          formData.append('file', el.file);
          const timestamp = Date.now()
          formData.append('unionid', Cookies.get('user_unionid_v3') || '')
          formData.append('timestamp', timestamp)
          formData.append('sign', md5(timestamp + 'ewqijfzdsq'))
          el.status = 'uploading';
          el.message = '上传中...';
          uploadFile(formData).then(res => {
            el.file.url = res.data.data.info
            el.status = 'done'
          }).catch(() => {
            el.status = 'failed';
            el.message = '上传失败';
          })
        })
      },
      onSubmit() {
        if (this.loading) {
          return
        }
        if (this.contact.trim() == '') {
          Toast('请填写您的联系方式')
          return
        }
        if (!isPhoneNumber(this.contact)) {
          Toast('手机号码格式不正确')
          return
        }
        if (this.content.trim() == '' ) {
          Toast('请填写反馈内容')
          return
        }
        
        this.loading = true
        const params = {
          photos: this.fileList.map(el => {
            return el.file.url
          }),
          remark: this.content,
          contact: this.contact
        }
        addFeedback(params).then(() => {
          this.loading = false
          Dialog.alert({
            title: '提交成功',
            confirmButtonColor: '#186AFF'
          }).then(() => {
            this.$router.go(-1)
          })
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>
<style lang="less">
  .fb-upload-container {
    margin-top: 4.6667vw;
    .van-uploader__preview {
      border-radius: 1.3333vw;
      overflow: hidden;
      margin-right: 4.6667vw;
    }
    .van-uploader__upload {
      border-radius: 1.3333vw;
      overflow: hidden;
      background: transparent;
      border: 1px dashed rgba(153, 153, 153, .39)
    }
  }
</style>